<template>
  <v-radio-group
    v-model="isTrue"
    mandatory
    @change="handleChange"
    row
  >
    <template slot="label">
      <h3>{{label}}: </h3>
    </template>
    <v-radio
      label="Yes"
      :value=true
    ></v-radio>
    <v-radio
      label="No"
      :value=false
    ></v-radio>
  </v-radio-group>
</template>

<script>

export default {
  props: {
    value: Boolean,
    label: String
  },
  data () {
    return {
      isTrue: this.value
    }
  },
  computed: {
  },
  async created () {
  },
  methods: {
    handleChange () {
      this.$emit('change', this.isTrue)
    }
  }}
</script>

</script>
<style lang="scss" scoped>
::v-deep .clickable {
  cursor: pointer
}
</style>
