<template>
  <v-container fluid>
    <v-btn
      color="primary"
      dark
      bottom
      right
      fab
      fixed
      @click="scrollToTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <div class="d-flex mb-4">
      <h2>Manage Contacts <span class="small"> ({{selectedContacts.length}} selected)</span></h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchText"
        label="Search"
        dense
        hide-details
        outlined
        clearable
      ></v-text-field>
    </div>
    <v-card v-if="selectedContacts.length" class="mb-4">
      <v-card-subtitle>Update selected contacts</v-card-subtitle>
      <v-card-text class="d-flex">
        <v-btn color="warning" class="mr-4" @click="removeCategories" >remove categories</v-btn>
        <v-select
          v-model="selectedCategories"
          :items="marketingCategories"
          :menu-props="{ maxHeight: '400' }"
          label="Set Contact Categories"
          multiple
          outlined
          dense
        ></v-select>
        <v-btn color="success" class="ml-4" @click="mergeCategories" :disabled="!selectedCategories.length" >Merge</v-btn>
        <v-btn color="success" class="ml-4" @click="replaceCategories" :disabled="!selectedCategories.length" >Replace</v-btn>
      </v-card-text>
    </v-card>
    <v-data-table
      class="mb-10"
      v-model="selectedContacts"
      :search="searchText"
      :items="contacts"
      :headers="headers"
      hide-default-footer
      :disable-pagination=true
      show-select
      item-key="contactID"
      dense
      @pagination="getSearchedItems"
    >
      <template v-slot:[`header.data-table-select`]></template>
      <template v-slot:[`item.contactID`]="{ item }">
        <a
          target="_blank"
          v-bind:href="
            'https://go.xero.com/Contacts/View/' + item.contactID
          "
          >Xero</a
        >
      </template>
    </v-data-table>
    <confirm-dialog ref="confirmDialog" />
  </v-container>
</template>

<script>
import ContactApi from '@/api/contact'
import { mapGetters } from 'vuex'
import BooleanRadioGroup from '@/components/BooleanRadioGroup'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: {
    BooleanRadioGroup,
    ConfirmDialog
  },
  data () {
    return {
      isCustomer: false,
      isSupplier: false,
      searchText: null,
      currentContact: null,
      searchedContacts: 0,
      selectedContacts: [],
      selectedCategories: [],
      marketingCategories: ['RETAIL','CUSTOM_ARTIST','PUBLISHED_ARTIST','AGENT_CONTACT','SPECIAL', 'ADMIN_USER'],
      contacts: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "emailAddress" },
        { text: "Customer / Supplier", value: "contactType" },
        // { text: "Supplier", value: "isSupplier", width: 100 },
        { text: "Logins", value: "user.loginCount", width: 100 },
        { text: "Category", value: "marketingCategory" },
        { text: "", value: "contactID" },
        { text: "Account Number", value: "accountNumber" },
      ],
    }
  },
  computed: {
    // ...mapGetters({
    //   contacts: "contacts/contacts",
    // }),
    // filteredContacts: function () {
    //   return this.contacts.filter(contact => {
    //     if (this.isCustomer && this.isSupplier ) {
    //       return ((contact.isCustomer === this.isCustomer) && (contact.isSupplier === this.isSupplier))
    //     } else return true
    //   })
    // }
  },
  async created () {
    const allContacts = await ContactApi.loadContacts()
    this.contacts = allContacts.data.filter( item => {
      if (item.contactType !== 'SUPPLIER') {
        item.marketingCategory.sort()
        return item
      }
    })
  },
  methods: {
    async mergeCategories () {
      if (
        await this.$refs.confirmDialog.open(
          `Merge marketing categories?`,
          `This will add these categories to all the selected contacts. Existing categories will be retained`
        )
      ) {
        const updatedContacts = this.selectedContacts.map( contact => {
          if (contact.marketingCategory[0] === 'UNDEFINED') { contact.marketingCategory = []}
          const marketingCategory = [...new Set([...this.selectedCategories, ...contact.marketingCategory])]
          contact.marketingCategory = marketingCategory
          return { contactID: contact.contactID, marketingCategory: marketingCategory }
        })
        await ContactApi.updateContacts(updatedContacts)
      }
    },
    async replaceCategories () {
      if (
        await this.$refs.confirmDialog.open(
          `Replace marketing categories?`,
          `This will replace the current contacts' marketing categories with the selected categories`
        )
      ) {
        const updatedContacts = this.selectedContacts.map( contact => {
          contact.marketingCategory = [...this.selectedCategories]
          return { contactID: contact.contactID, marketingCategory: [...this.selectedCategories] }
        })
        await ContactApi.updateContacts(updatedContacts)
      }
    },
    async removeCategories () {
      if (
        await this.$refs.confirmDialog.open(
          `Remove marketing category(s)?`,
          `This will set all the selected contacts marketing category to 'UNDEFINED'. This means that they won't appear in any marketing mailing lists`
        )
      ) {
        const updatedContacts = this.selectedContacts.map( contact => {
          contact.marketingCategory = ['UNDEFINED']
          return { contactID: contact.contactID, marketingCategory: ['UNDEFINED'] }
        })
        await ContactApi.updateContacts(updatedContacts)
      }
    },
    getSearchedItems (pagination) {
      this.searchedContacts = pagination.itemsLength
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }}
</script>

</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.18) !important;
}
</style>
